<script>
  import SectionHeader from "./SectionHeader.svelte";
</script>

<div>
  <SectionHeader title="Well, this is awkward" />
  <p class="mt-4 mb-8">
    Something went wrong while fetching the content for this page. Please try
    refreshing the page, and if the error perists, <a
      class="text-secondary"
      href="https://frankkmods.com/#/contact"
      target="_blank">let me know</a
    >.
  </p>
  <p class="text-subtle text-sm">Error 404</p>
</div>
